import cx from 'classnames'
import { useMemo } from 'react'

import { SanityLogoGrid } from '@data/sanity/queries/types/modules'
import { backgroundColorClasses } from '@lib/color'
import {
  getDefaultLogoGridVerticalPaddingClasses,
  getSpacingClasses,
} from '@lib/spacing'

type LogoGridProps = SanityLogoGrid

const LogoGrid = ({
  title,
  logos,
  spacing,
  background,
  noItemGaps,
  reverseSequence,
}: LogoGridProps) => {
  const sectionClasses = useMemo(
    () => [
      background ? backgroundColorClasses[background] : null,
      ...getDefaultLogoGridVerticalPaddingClasses(spacing?.padding),
      getSpacingClasses(spacing?.padding, 'right'),
      getSpacingClasses(spacing?.padding, 'left'),
    ],
    [background, spacing]
  )
  const items = useMemo(() => {
    const items = logos ? [...logos] : []

    return reverseSequence ? items.reverse() : items
  }, [logos, reverseSequence])

  return (
    <section className={cx(sectionClasses)}>
      <div className="container">
        {title && (
          <h4 className="text-gray text-base mb-10 text-center">{title}</h4>
        )}

        <div
          className={cx(
            'flex gap-8 md:gap-x-16 xl:gap-x-20 flex-wrap justify-center items-center',
            {
              '!gap-x-0 !gap-y-0': noItemGaps,
            }
          )}
        >
          {items.map(({ url, alt }, index) => {
            const altKey = alt.replaceAll(' ', '-').toLocaleLowerCase()
            const key = `logo-grid-item-${index}-${altKey}`

            return (
              <picture key={key} className="max-w-[11rem]">
                <img src={url} alt={alt} />
              </picture>
            )
          })}
        </div>
      </div>
    </section>
  )
}

export default LogoGrid
